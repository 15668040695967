import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("@/views/LogoutView.vue"),
    },
    {
      path: "/loggedout",
      name: "loggedOut",
      component: () => import("@/views/LoggedOutView.vue"),
    },
    {
      path: "/page-not-found",
      name: "page-not-found",
      component: () => import("@/views/PageNotFoundView.vue"),
    },
    {
      path: "/t/:token",
      name: "tokenLogin",
      component: () => import("../views/TokenLoginView.vue"),
    },
    {
      path: "/criteria-no-match",
      name: "criteria-no-match",
      component: () => import("../views/CriteriaNoMatchView.vue"),
    },
    {
      path: "/exclusion",
      name: "exclusion",
      component: () => import("../views/ExclusionView.vue"),
    },
    {
      // Note: AuthenticatedAppView.vue is a wrapper that has its own custom router (i.e. navStore)
      path: "/:token",
      name: "app",
      component: () => import("../views/AuthenticatedAppView.vue"),
    },
    { path: "/:pathMatch(.*)*", redirect: "/page-not-found" },
  ],
});

//////////////////////////////////////////////////////
//
//  Authentication guard
//
//////////////////////////////////////////////////////
router.beforeEach(async (to, from) => {
  if (to.name !== "app") {
    return true;
  }

  const authStore = useAuthStore();
  if (!authStore.isAuthenticated) {
    if (to.params.token) {
      return { name: "tokenLogin", params: { token: to.params.token } };
    }
  } else {
    // User is already authenticated. Check if the token in the URL matches the one in the store.
    if (to.params.token !== authStore.original_login_token) {
      // User tempered with the URL.
      return { name: "page-not-found" };
    }
  }

  return true;
});
