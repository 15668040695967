import { ref, computed, watch } from "vue";
import { defineStore } from "pinia";

const LOCAL_STORAGE_KEY = "api_token";
const LOCAL_STORAGE_ORIGINAL_LOGIN_TOKEN_KEY = "original_login_token";

export const useAuthStore = defineStore("auth", () => {
  const api_token = ref(localStorage.getItem(LOCAL_STORAGE_KEY) ?? null);
  const original_login_token = ref(
    localStorage.getItem(LOCAL_STORAGE_ORIGINAL_LOGIN_TOKEN_KEY) ?? null
  );
  const isAuthenticated = computed(() => api_token.value !== null);

  watch(api_token, (newToken) => {
    if (newToken === null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, newToken);
    }
  });

  watch(original_login_token, (newToken) => {
    if (newToken === null) {
      localStorage.removeItem(LOCAL_STORAGE_ORIGINAL_LOGIN_TOKEN_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_ORIGINAL_LOGIN_TOKEN_KEY, newToken);
    }
  });

  const reset = () => {
    api_token.value = null;
    // original_login_token.value = null;
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_ORIGINAL_LOGIN_TOKEN_KEY);
  };

  return {
    api_token,
    original_login_token,
    isAuthenticated,
    reset,
  };
});
