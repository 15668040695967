import { ref, watch } from "vue";
import { defineStore } from "pinia";

export const LOCAL_STORAGE_KEY = "locale";
const INITIAL_VALUES = {
  locale: localStorage.getItem(LOCAL_STORAGE_KEY) ?? navigator.language.slice(0, 2),
};

export const useLocaleStore = defineStore("locale", () => {
  const locale = ref(INITIAL_VALUES.locale);

  watch(locale, (newLocale) => {
    if (newLocale === null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, newLocale);
    }
  });

  const reset = () => {
    locale.value = INITIAL_VALUES.locale;
  };

  const switchTo = (newLocale) => {
    locale.value = newLocale;
  }

  return { locale, switchTo, reset };
});
