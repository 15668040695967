import { ref, watch, computed } from "vue";
import { defineStore } from "pinia";

const LOCAL_STORAGE_KEY = "theme";
export const useThemeStore = defineStore(LOCAL_STORAGE_KEY, () => {
  const systemPref =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

  const current = ref(
    localStorage.getItem(LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
      : systemPref
  );

  watch(current, (newVal, oldVal) => {
    if (newVal === oldVal) return;
    document
      .querySelector("html")
      .classList[newVal === "dark" ? "add" : "remove"]("dark");
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newVal));
  });

  const useDarkTheme = () => {
    current.value = "dark";
  };

  const useLightTheme = () => {
    current.value = "light";
  };

  const initializeTheme = () => {
    console.log("initializeTheme", isDark.value);
    document
      .querySelector("html")
      .classList[isDark.value ? "add" : "remove"]("dark");
  };

  const toggleTheme = () => {
    current.value = isDark.value ? "light" : "dark";
  };

  const isDark = computed(() => current.value === "dark");

  return {
    initializeTheme,
    useDarkTheme,
    useLightTheme,
    toggleTheme,
    isDark,
  };
});
