<script setup>
import { storeToRefs } from "pinia";

import { ArrowRightOnRectangleIcon, SunIcon } from "@heroicons/vue/24/outline";
import { SunIcon as SunIconSolid } from "@heroicons/vue/24/solid";

import { router } from "@/router/router";
import {
  useAuthStore,
  useNavStore,
  useDistributionStore,
  useThemeStore,
} from "@/stores";
import { authService } from "@/api/";
import nQuerioLogoWhite from "../../assets/img/n_white.svg";
import nQuerioLogoColor from "../../assets/img/n_color.svg";

const authStore = useAuthStore();
const navStore = useNavStore();
const distributionStore = useDistributionStore();

const { toggleTheme } = useThemeStore();
const { isDark } = storeToRefs(useThemeStore());

const logout = async () => {
  await authService.logout().finally(() => {
    authStore.reset();
    navStore.reset();
    distributionStore.reset();
    router.push({ name: "loggedOut" });
  });
};
</script>

<template>
  <nav class="navbar bg-gray-800 h-12 flex items-center">
    <div
      class="max-w-7xl w-full flex mx-auto px-4 sm:px-6 lg:px-8 print:hidden"
    >
      <div class="flex-1">
        <img
          class="h-8 w-auto cursor-pointer opacity-30 hover:opacity-100"
          :src="nQuerioLogoWhite"
          alt="nQuerio Logo"
          @mouseover="(e) => (e.target.src = nQuerioLogoColor)"
          @mouseleave="(e) => (e.target.src = nQuerioLogoWhite)"
          @click="
            () => {
              if (authStore.isAuthenticated) {
                if (navStore.current.name !== 'conclusion') {
                  navStore.push({ name: 'welcome' });
                } else {
                  // do nothing
                }
              } else {
                router.push({ name: 'loggedOut' });
              }
            }
          "
        />
      </div>
      <div
        class="cursor-pointer"
        @click="toggleTheme"
        :title="$t('buttons.toggleThemeToLight')"
      >
        <SunIconSolid
          v-if="isDark"
          class="h-8 w-8 text-gray-500 mr-3 hover:text-gray-50"
          aria-hidden="true"
        />
      </div>
      <div
        class="cursor-pointer"
        @click="toggleTheme"
        :title="$t('buttons.toggleThemeToDark')"
      >
        <SunIcon
          v-if="!isDark"
          class="h-8 w-8 text-gray-500 mr-3 hover:text-gray-50"
          aria-hidden="true"
        />
      </div>
      <div
        v-if="authStore.isAuthenticated"
        class="cursor-pointer"
        @click="logout"
        :title="$t('buttons.logout')"
      >
        <ArrowRightOnRectangleIcon
          v-if="authStore.isAuthenticated"
          class="w-8 text-gray-500 hover:text-gray-50"
          aria-hidden="true"
        />
      </div>
    </div>
  </nav>
</template>
