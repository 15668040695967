import { ref, watch } from "vue";
import { defineStore } from "pinia";

const LOCAL_STORAGE_KEY = "distribution_id";
const LOCAL_STORAGE_IS_TEST_KEY = "distribution_is_test";

export const useDistributionStore = defineStore("distribution", () => {
  const distribution_id = ref(
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || null
  );
  watch(distribution_id, (newDistributionId) => {
    if (newDistributionId === null) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEY, newDistributionId);
    }
  });

  const distribution_is_test = ref(false);
  watch(distribution_is_test, (newDistributionIsTest) => {
    if (newDistributionIsTest == true) {
      localStorage.setItem(LOCAL_STORAGE_IS_TEST_KEY, "true");
    } else {
      localStorage.removeItem(LOCAL_STORAGE_IS_TEST_KEY);
    }
  });

  const reset = () => {
    distribution_id.value = null;
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    localStorage.removeItem(LOCAL_STORAGE_IS_TEST_KEY);
  };

  return {
    distribution_id,
    distribution_is_test,
    reset,
  };
});
