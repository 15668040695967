<script setup>
  import { onMounted } from "vue";
  import { useThemeStore, useDistributionStore } from "@/stores";
  import { RouterView } from "vue-router";
  import BrowserBackHandler from "@/hoc/BrowserBackHandler.vue";
  import DevTools from "@/views/DevToolsView.vue";
  import TopNav from "@/components/layout/TopNav.vue";
  import Footer from "@/components/layout/Footer.vue";

  const isLocal = import.meta.env.MODE === "development";

  const distributionStore = useDistributionStore();

  onMounted(() => {
    useThemeStore().initializeTheme();
  });
</script>

<!--
    This template requires:

    ```
    <html class="h-full">
      <body class="h-full">
        <div id="app" class="min-h-full"></div>
    ```
  -->
<template>
  <BrowserBackHandler>
    <div class="flex flex-col min-h-screen">
      <TopNav />
      <main class="w-full max-w-7xl mx-auto py-10 px-4 md:px-8">
        <RouterView v-slot="{ Component }">
          <template v-if="Component">
            <component :is="Component"></component>
          </template>
        </RouterView>
      </main>
      <footer class="sticky top-[100vh]">
        <Footer />
      </footer>
    </div>
  </BrowserBackHandler>
  <DevTools v-if="isLocal || distributionStore.distribution_is_test" />
</template>
