import { fetchWrapper } from "@/helpers";

const BASE_URL = `${import.meta.env.VITE_API_URL}`;

const tokenLogin = async (token) => {
  return await fetchWrapper.get(`${BASE_URL}/tokens/${token}/login`);
};

const logout = async () => {
  return await fetchWrapper.get(`${BASE_URL}/logout`).finally(() => {
    localStorage.clear();
  });
};

export const authService = {
  tokenLogin,
  logout,
};
