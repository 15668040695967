<template>
  <footer class="sticky top-[100vh] pb-2">
    <span class="block text-xs text-gray-500 text-center dark:text-gray-400"
      >v{{ version }}&nbsp;&nbsp;© {{ yearOfDate }}
      <a href="https://nquerio.ca/fr" target="_blank" class="hover:underline"
        >nQuerio <small>inc</small></a
      >. Tous droits réservés.
    </span>
  </footer>
</template>

<script setup>
  const date = new Date();
  const yearOfDate = date.getFullYear();
  const version = __APP_VERSION__;
</script>
