import { useAuthStore, useNavStore, useDistributionStore } from "@/stores";
import { router } from "@/router/router";

const request = (method) => {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    requestOptions.headers["Accept"] = "application/json";
    if (method != "GET" && body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
};

/////////////////////////////////////////
// Helpers
/////////////////////////////////////////

const authHeader = (url) => {
  // return auth header with api_token if exists and request is to the api url
  const isApiUrl = url.startsWith(import.meta.env.VITE_API_URL);
  if (isApiUrl) {
    const { isAuthenticated, api_token } = useAuthStore();
    if (isAuthenticated) {
      return { Authorization: `Bearer ${api_token}` };
    }
  }
  return {};
};

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    const navStore = useNavStore();

    if (!response.ok) {
      const error = { response: response, data: data };
      // auto logout if 401 - Unauthorized response returned from api
      if (response.status === 401) {
        const authStore = useAuthStore();
        const distributionStore = useDistributionStore();
        authStore.reset();
        navStore.reset();
        distributionStore.reset();
        localStorage.clear();
        router.push({ name: "loggedOut" });
        return;
      } else if (response.status === 404 || response.status === 303) {
        /// Silently ignore 404 errors
      }else {
        // alert("An error occurred.");
      }
      console.log(error);
      return Promise.reject(error);
    }

    if (response.status === 204) return Promise.resolve(response);

    return Promise.resolve(data);
  });
};

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};
