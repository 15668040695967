<script setup>
  import { onBeforeUnmount, onMounted } from "vue";
  import { useNavStore } from "@/stores";
  import { useI18n } from "vue-i18n";

  const navStore = useNavStore();
  const { t } = useI18n();

  // Triggered when the user presses the back, forward, or refresh button.
  const handleBrowserHistoryChanged = () => {
    alert(t("error.browserNav.message"));
  };
  // const handleBrowserHistoryChanged = () => {
  //   console.log("back button pressed");
  //   if (navStore.previous() === null) {
  //     restoreBackButton();
  //   } else {
  //     navStore.back();
  //   }
  // };

  // const restoreBackButton = () => {
  //   console.log("restoring back button");
  //   window.removeEventListener("popstate", handleBrowserHistoryChanged);
  //   window.onpopstate = null;
  //   window.history.back();
  // };

  onMounted(() => {
    window.addEventListener("popstate", handleBrowserHistoryChanged);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  });

  onBeforeUnmount(() => {
    window.removeEventListener("popstate", handleBrowserHistoryChanged);
  });
</script>
<template>
  <slot />
</template>
